<template>
    <Productos />
</template>

<script>
import Productos from '../components/Productos.vue';


export default {
  name: 'Productall',
  
  components: {
    Productos,
  },

};
</script>
