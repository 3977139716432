<template>


<div class="js-slider stage-video block-stage block-stage--regular stage-video--disable-nav" data-slider-stage="" data-slider-stage-disable="stage-video--disable-nav" data-slider-video="" data-slider-autoplay="true" data-slider-stage-playing="stage-video--playing">
    <div class="js-slider-items block-stage__items stage-video__items slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
            <div class="slick-track" style="opacity: 1; width: 1903px; transform: translate3d(0px, 0px, 0px);" role="listbox">
                <div class="block-stage__item slick-slide slick-current slick-active" style="width: 1903px;" tabindex="-1" role="option" aria-describedby="slick-slide00" data-slick-index="0" aria-hidden="false">
                    <picture class="block-stage__picture lazy-placeholder-loader--loaded">
                        <source media="(max-width: 360px)" srcset="/imagenes/IMAGENES_PRODUCTOS/5.jpg">
                        <source media="(max-width: 479px)" srcset="/imagenes/IMAGENES_PRODUCTOS/4.jpg">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/3.jpg">
                        <source media="(max-width: 1024px)" srcset="/imagenes/IMAGENES_PRODUCTOS/2.jpg">
                        <source media="(min-width: 1025px)" srcset="/imagenes/IMAGENES_PRODUCTOS/1.jpg">
                        <img src="" alt="" class="block-stage__img">
                    </picture>
                    <div class="block-stage__content">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<a name="aereo"></a>
<div class="wrapper wrapper--gutter">
  <div class="text-block-intro-page">
      <h1 >Productos </h1>
      <div>
          <p>La eficiencia y los altos estándares son la clave de su éxito y el nuestro. En Sunrise Cargo, tenemos la experiencia para gestionar sus necesidades logísticas y de transporte, todo desde un solo lugar. Independientemente del tamaño y la naturaleza de su negocio, y de la complejidad de sus operaciones, nos encargaremos de su logística por carretera, ferrocarril, aire y mar..<br></p>
      </div>
  </div>
</div>

<section class="section has-margin has-tabs">
    <div class="wrapper block-teaser-full-overlay__wrapper">

        <a name="maritimo"></a>
        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:46 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Transporte aéreo</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row-reverse
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/aereo.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/aereo.png" alt="Land transport border crossing DB Schenker trucks" class="">
                    </picture>
                    <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right" style="background-color:rgba(46,49,138,.85);">
                        <h2 class="h3 block-teaser__title">Transporte aéreo</h2>
                        <p data-truncate-lines="8" style="">Sunrise Cargo es un proveedor de servicios de transporte aéreo líder en el mercado con una extensa red global. Nuestras soluciones de envío avanzadas e integradas garantizan el transporte de su carga hasta su destino, en cualquier parte del mundo. </p>
                        <p data-truncate-lines="8" style="">Contamos con una fuerte presencia en los principales hubs tales como Miami, Frankfurt, Madrid, Shanghai y Hong Kong, con precios atractivos y un eficiente servicio. </p>
                        <p data-truncate-lines="8" style="">A nivel nacional contamos con oficinas propias los aeropuertos de Bogotá, Medellín, Cali, Pereira, Barranquilla y Cartagena </p>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Transporte marítimo</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/maritimo.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/maritimo.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">Transporte marítimo</h2>
                        <p style="" data-truncate-lines="8">
                            A través del servicio de transporte marítimo despachamos más de 12.000 contenedores anuales desde y hacia los puertos de todo mundo, convirtiéndonos así en una empresa de transporte líder para sus envíos de carga marítima. Dondequiera que necesite que vayan sus envíos, nuestras soluciones innovadoras forman el puente hacia sus mercados de ventas y compras. 
                        </p>
                        <p style="" data-truncate-lines="8">
                            Los viajes consistentes y confiables aseguran que sus envíos de carga marítima siempre lleguen a sus clientes de manera oportuna. 
                        </p>
                        <a name="terrestre"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:46 PM">            
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Transporte terrestre</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row-reverse
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/Terrestre.png" alt="Land transport border crossing DB Schenker trucks" class="">
                    </picture>
                    <a name="almacenamiento_distribucion"></a>
                    <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right" style="background-color:rgba(46,49,138,.85);">
                        <h2 class="h3 block-teaser__title">Transporte terrestre</h2>
                        <p data-truncate-lines="8" style="">Sunrise Cargo selecciona cuidadosamente proveedores de transporte terrestre habilitados que cumplan con los más altos estándares de seguridad y calidad. Obteniendo el alcance que necesita para mantenerse por delante de la competencia con una alta gama de soluciones de transporte terrestre de calidad. </p>
                        <p data-truncate-lines="8" style="">Ya sea que necesite transporte por carretera, nuestras soluciones de envío terrestre llevarán sus envíos donde sea que necesiten estar, de manera puntual y confiable. No importa si necesita un solo envío de paquete o si desea que gestionemos toda su cadena de suministro de superficie. Tenemos proveedores de transporte terrestre habilitados que puede mover cualquier mercancía a cualquier lugar a nivel nacional. </p>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Almacenamiento &amp; distribución</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/almacenamiento.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">Logística &amp; Distribución</h2>
                        <p style="" data-truncate-lines="8">
                            Sunrise Cargo S.A.S. opera sus propias bodegas, que suman un total de 17.000 metros cuadrados en Bogotá.
                        </p>
                        <p style="" data-truncate-lines="8">
                            Las bodegas de Sunrise Cargo S.A.S. se encuentran en Siberia, el eje central de logística de la ciudad de Bogotá y dentro del complejo industrial San Cayetano, en las cercanías del aeropuerto El Dorado. Contamos con un sofisticado sistema de seguridad y tecnología de almacenamiento, manejo de inventarios y distribución de última generación.
                        </p>
                        <p style="" data-truncate-lines="8">
                            Con estas instalaciones Sunrise Cargo S.A.S. ofrece la cadena logística completa, incluyendo picking & packing, empaque y distribución a nivel nacional.
                        </p>
                        <a name="aduanero"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:46 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Agenciamiento Aduanero</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row-reverse
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/agenciamiento.png" alt="Land transport border crossing DB Schenker trucks" class="">
                    </picture>
                    <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right" style="background-color:rgba(46,49,138,.85);">
                        <h2 class="h3 block-teaser__title">Agenciamiento aduanero</h2>
                        <p data-truncate-lines="8" style="">Sunrise Cargo S.A.S. ha seleccionado cuidadosamente agencias de Aduanas
                            Nivel 1, con operación propia y directa en Colombia a nivel nacional.
                            Los cuales tienen programas de calidad y seguridad certificados hace más de 10
                            años.
                        </p>
                        <p data-truncate-lines="8" style="">Las operaciones de nacionalización ejecutadas por las agencias de aduana
                            previamente seleccionadas son coordinadas y supervisadas por nuestro propio
                            equipo, pudiendo así garantizar un óptimo servicio a los clientes de Sunrise Cargo.
                        </p>
                        <a name="proyectos"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item dbs-accordion-item--first " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:46 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Proyectos y carga extralarga</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row-reverse
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Proyectos-y-CARGA-EXTRADIMENSIONADA.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/aereo.png" alt="Land transport border crossing DB Schenker trucks" class="">
                    </picture>
                    <a name="otm"></a>
                    <div class="block-teaser__text theme-dark block-teaser__text--with-links align-right" style="background-color:rgba(46,49,138,.85);">
                        <h2 class="h3 block-teaser__title">Proyectos y carga extradimensionada</h2>
                        <p data-truncate-lines="8" style="">Cuenta con empresas de transporte especializadas en logísticas para la ejecución de
                            proyectos que incluyen transporte de carga extrapesada y/o extra dimensionada en
                            Colombia, así como el suministro de grúas y equipo de izaje para montajes de obras
                            industriales y civiles, con equipos de última tecnología, buscando satisfacer las
                            necesidades del mercado.
                        </p>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>OTM</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/OTM.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/OTM.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">OTM</h2>
                        <p style="" data-truncate-lines="8">Sunrise Cargo cuenta con empresas de transporte debidamente
                            certificadas en calidad y seguridad y registradas ante el Mintransporte, garantizando
                            a nuestros clientes una satisfacción del servicio; las cuales han pasado previamente
                            por un riguroso proceso de selección. 
                        </p>
                        <p style="" data-truncate-lines="8">
                            Para así poder ofrecer servicios integrales
                            como operador de transporte multimodal y transporte de carga terrestre a nivel
                            nacional; desde los puertos marítimos colombianos y las principales ciudades del
                            país en carga de importaciones y exportaciones, servicio de ITR, distribución
                            nacional y urbana (masivo y semi masivo).
                        </p>
                        <a name="seguro"></a>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dbs-accordion-item dbs-accordion-item--mobile-only js-detail-nav-item " data-accordion-active="dbs-accordion-item--active" data-accordion-group="Oct 21, 2021, 8:04:47 PM">
            <button class="js-detail-nav-toggle dbs-accordion-item__switch">
                <b>Seguro de transporte de mercancía</b>
                <span class="dbs-accordion-item__arrow">
                    <svg class="icon icon--down-arrow icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
                        <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
                        <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
                    </svg>
                </span>
            </button>
            <div class="dbs-accordion-item__content">
                <div class="block-teaser--full-overlay
                    block-teaser--row
                    ">
                    <picture class="block-teaser__media">
                        <source media="(max-width: 394px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(max-width: 507px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(max-width: 590px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(max-width: 767px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(max-width: 934px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(max-width: 1023px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(max-width: 1050px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <source media="(min-width: 1051px)" srcset="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png">
                        <img src="/imagenes/IMAGENES_PRODUCTOS/Seguro-de-transporte.png" alt="DB Schenker Employee Air Freight Product" class="">
                    </picture>
                    <div class="block-teaser__text theme-light block-teaser__text--with-links align-left" style="background-color:rgb(234 239 239 / 85%);">
                        <h2 class="h3 block-teaser__title">Seguro de transporte de mercancía</h2>
                        <p style="" data-truncate-lines="8">Todas las mercancías estarán amparadas bajo las condiciones de la póliza E-cargo
                            automática de Chubb de Colombia S.A. con el sistema puerta a puerta, incluyendo
                            las operaciones de cargue y descargue, es decir, desde el momento en que el
                            asegurado recibe la carga y asume la responsabilidad sobre ellas, hasta su destino
                            final en bodega (En todo caso, está sujeto al término de negociación INCOTERMS).
                        </p>
                        <div class="grid grid--wide"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
export default {


}
</script>

<style>

</style>